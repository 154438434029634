import { StyledLayout, Top } from './styled';

import { CookieBanner } from 'components';
import { Footer } from '../Footer';
import { LayoutProps } from './types';
import { Navbar } from '../Navbar';

export const Layout = ({ children }: LayoutProps): JSX.Element => {
  return (
    <>
      <StyledLayout>
        <Top id="#top" />
        <Navbar />
        {children}
        <Footer />
        <CookieBanner/>
      </StyledLayout>
    </>
  );
};
