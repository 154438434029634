import { LiveLink } from 'components';
import { LiveLinkWrapper } from './styled';
import { LiveLinksProps, LiveLink as LiveLinkType } from './types';

export const LiveLinks = ({ links }: LiveLinksProps): JSX.Element => (
  <LiveLinkWrapper>
    {links.map(
      (link: LiveLinkType, index: number): JSX.Element => (
        <LiveLink icon={link.icon} key={index} link={link.href}>
          {link.label}
        </LiveLink>
      )
    )}
  </LiveLinkWrapper>
);
