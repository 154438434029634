import { Content, ProjectGrid, Wrapper } from './styled';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { Lightbox } from 'components';
import NextImage from 'next/image';
import { Project as ProjectType } from 'data/projects';
import { projectPreviews } from 'data';
import { useRouter } from 'next/router';

export const PersonalWork = (): JSX.Element => {
  const { asPath } = useRouter();

  const [lightboxController, setLightboxController] = useState({
    toggler: false,
    slide: 1,
  });

  const handleOnClickProject = useCallback((num: number) => {
    setLightboxController({
      toggler: !lightboxController.toggler,
      slide: num,
    });
  }, [lightboxController]);

  useEffect(() => {
    const hash = asPath.split('#')[ 1 ];

    if (hash) {
      setLightboxController({
        toggler: !lightboxController.toggler,
        slide: Number(hash),
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const imageSources = useMemo(() => projectPreviews.personal.map((project: ProjectType) => project.image.src), []);

  const renderProjects = useMemo(() => {
    return projectPreviews.personal.map((project: ProjectType, index: number): JSX.Element => {
      const { image, name } = project;
      return (
        <Wrapper key={index} onClick={() => handleOnClickProject(index + 1)}>
          <NextImage
            alt={name}
            placeholder="blur"
            src={image}
            style={ { height: '100%', width: '100%', objectFit: 'cover' } }
          />
        </Wrapper>
      );
    })
  }, [handleOnClickProject]);

  return (
    <>
      <Content id="personal-preview">
        <ProjectGrid>
          {renderProjects}
        </ProjectGrid>
      </Content>
      <Lightbox controller={lightboxController} sources={imageSources} />
    </>
  );
};
