import {
  Background,
  CVButton,
  Content,
  Details,
  Heading,
  ProfilePicWrapper,
  Skills,
  StyledAbout,
} from './styled';

import NextImage from 'next/image';
import { Skill } from 'components';
import digitalDrawing from 'assets/icons/digital-drawing.svg';
import production from 'assets/icons/production.svg';
import profilePic from 'assets/images/aboutMe_profileImage.png';
import uiDesign from 'assets/icons/ui-design.svg';

export const About = (): JSX.Element => {
  const onCVDownload = () => {
    window.open('/ZivPesso_CV.pdf');
  };

  const totalExperience = new Date().getFullYear() - 2011;

  return (
    <StyledAbout id="about">
      <Background>
        <Content>
          <ProfilePicWrapper>
            <NextImage
              alt="Profile Picture"
              placeholder="blur"
              src={profilePic}
              style={{ height: '100%', objectFit: 'contain', width: '100%' }}
            />
          </ProfilePicWrapper>
          <Details>
            <Heading>
              <h2>About Me</h2>
              <CVButton
                ariaLabel="Download CV"
                label="Download CV"
                onClick={onCVDownload}
              />
            </Heading>
            <hr />
            <p>
              I am an Israeli/Panamanian UI Artist based in Austin, TX. My
              education in Illustration helped me get a career in mobile gaming
              creating Concept Art, which is where I developed an interest in
              User Interfaces. I have been in the industry for over {totalExperience} years
              and have shipped multiple products. I enjoy being challenged
              creatively and growing artistically by acquiring new skills.
            </p>
          </Details>
        </Content>
      </Background>

      <Skills>
        <Skill
          icon={digitalDrawing}
          text="My Illustration background provides me with skills in drawing and painting using traditional and digital mediums. Using Photoshop, I am able to create final production assets from beginning to completion."
          title="Digital Drawing and Painting"
        />
        <Skill
          icon={uiDesign}
          text="I have knowledge in Sketch, Figma, and Illustrator for mocking screens and Inivision for prototyping. I like to use After Effects to communicate more complex animations to my team. Moreover, I'm able to implement UI in Unity, making sure it scales and anchors depending on the screen size."
          title="UI Design"
        />
        <Skill
          icon={production}
          text={`During my ${totalExperience} years of experience in the mobile game industry, I have managed to work with teams of various sizes. I enjoy taking on challenging problems to solve through iteration and communication with my co-workers.`}
          title="Production Team Experience"
        />
      </Skills>
    </StyledAbout>
  );
};
