import { HeadProps } from './types';
import NextHead from 'next/head';

export const Head = ({ children, pageTitle }: HeadProps): JSX.Element => {
  const title = `Ziv Pesso | UI Artist Portfolio${pageTitle ? ` | ${pageTitle}` : ''}`;

  return (
    <NextHead>
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <meta httpEquiv="X-UA-Compatible" content="ie=edge" />

      <title>{title}</title>
      <meta
        name="description"
        content="Ziv Pesso is a UI Artist based in California, creating delightful User Interfaces and Concept Art in the gaming industry."
      />
      <meta name="theme-color" content="#4a90e2" />
      <meta name="robots" content="index,follow" />

      <link rel="canonical" href="https://zivpesso.com" />

      <link
        href="/favicon.ico"
        rel="shortcut icon"
        sizes="any"
        type="image/ico"
      />
      <link
        rel="apple-touch-icon"
        href="/apple-touch-icon.png"
        type="image/png"
      />
      <link
        rel="apple-touch-icon"
        href="/apple-touch-icon-57x57.png"
        type="image/png"
        sizes="57x57"
      />
      <link
        rel="apple-touch-icon"
        href="/apple-touch-icon-72x72.png"
        type="image/png"
        sizes="72x72"
      />
      <link
        rel="apple-touch-icon"
        href="/apple-touch-icon-76x76.png"
        type="image/png"
        sizes="76x76"
      />
      <link
        rel="apple-touch-icon"
        href="/apple-touch-icon-114x114.png"
        type="image/png"
        sizes="114x114"
      />
      <link
        rel="apple-touch-icon"
        href="/apple-touch-icon-120x120.png"
        type="image/png"
        sizes="120x120"
      />
      <link
        rel="apple-touch-icon"
        href="/apple-touch-icon-144x144.png"
        type="image/png"
        sizes="144x144"
      />
      <link
        rel="apple-touch-icon"
        href="/apple-touch-icon-152x152.png"
        type="image/png"
        sizes="152x152"
      />
      <link
        rel="apple-touch-icon"
        href="/apple-touch-icon-180x180.png"
        type="image/png"
        sizes="180x180"
      />

      {children}
    </NextHead>
  );
};
