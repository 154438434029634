import { Icon, Logo } from 'components';
import { Menu, Nav, NavLinks, Socials } from './styled';

import { DropdownMenu } from './DropdownMenu';
import NextImage from 'next/image';
import NextLink from 'next/link';
import emailIcon from 'assets/icons/svg/mail.svg';
import instagramLogo from 'assets/icons/svg/instagram.svg';
import linkedinLogo from 'assets/icons/svg/linkedin.svg';
import { useState } from 'react';

export const Navbar = (): JSX.Element => {
  const [openMenu, setOpenMenu] = useState<boolean>(false);

  const toggleMenu = () => {
    openMenu ? setOpenMenu(false) : setOpenMenu(true);
  };

  return (
    <Nav>
      <Logo height="2.25rem" width="2.835rem" />
      <Socials className="social-icons">
        <NextLink
          aria-label="Visit my LinkedIn profile"
          href="https://www.linkedin.com/in/zivpesso/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <NextImage
            alt="Logo"
            src={linkedinLogo}
            style={ { objectFit: 'contain' } }
          />
        </NextLink>
        <NextLink
          aria-label="Browse my personal work on Instagram"
          href="https://www.instagram.com/zivpessoart/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <NextImage
            alt="Logo"
            src={instagramLogo}
            style={ { objectFit: 'contain' } }
          />
        </NextLink>
        <NextLink aria-label="Get in touch via email" href="mailto:zivpesso@gmail.com">
          <NextImage
            alt="Logo"
            src={emailIcon}
            style={ { objectFit: 'contain' } }
          />
        </NextLink>
      </Socials>
      <NavLinks>
        <NextLink aria-label="Navigate to the Portfolio section" href="/#portfolio">Portfolio</NextLink>
        <NextLink aria-label="Navigate to the About section" href="/#about">About</NextLink>
        <NextLink aria-label="Navigate to the Contact section" href="/#contact">Contact</NextLink>
      </NavLinks>
      <Menu aria-label="Toggle the navigation menu">
        <Icon type="menu" height="1.8rem" width="1.8rem" onClick={toggleMenu} />
      </Menu>

      {openMenu && <DropdownMenu toggleMenu={toggleMenu} />}
    </Nav>
  );
};
