import { BadgeWrapper, Copyright, CopyrightLinks, ScrollToTopButton, StyledFooter } from './styled';

import { Icon } from 'components/Icon';
import Link from 'next/link';
import NextImage from 'next/image';

export const Footer = (): JSX.Element => {
  const currentYear = new Date().getFullYear();

  return (
    <StyledFooter>
      <CopyrightLinks>
        <Link href="/copyright" passHref>
          <Copyright>Copyright © {currentYear} Ziv Pesso. All Rights Reserved.</Copyright>
        </Link>
        <Link
          href="https://www.dmca.com/Protection/Status.aspx?ID=91838995-d6a7-45bb-aa66-6b154cf208e4" title="DMCA.com Protection Status"
          passHref
        >
          <BadgeWrapper className="dmca-badge">
            <NextImage
              alt="DMCA.com Protection Status"
              src ="https://images.dmca.com/Badges/dmca_protected_sml_120n.png?ID=91838995-d6a7-45bb-aa66-6b154cf208e4"
              height={20}
              width={100}
            />
          </BadgeWrapper>
        </Link>
      </CopyrightLinks>
      <ScrollToTopButton aria-label="Scroll back to the top of the page" href="#top">
        <Icon
          color="var(--light_01)"
          height="0.8rem"
          width="1rem"
          type="chevronUp"
        />{' '}
        Back to Top
      </ScrollToTopButton>
    </StyledFooter>
  );
};
