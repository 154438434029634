import styled from 'styled-components';

export const StyledHeader = styled.header`
  background-image: var(--gradient_primary);
  color: var(--light_01);
  padding: 0.5rem 0;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media only screen and (min-width: 936px) {
    flex-direction: row;

    padding: 1.5rem 0;
  }
`;

export const Divider = styled.div`
  display: none;

  @media only screen and (min-width: 936px) {
    display: block;

    background-color: var(--light_01);
    height: 7rem;
    width: 4px;
  }
`;

export const Welcome = styled.div`
  font-family: var(--heading-font);
  font-size: 4rem;
  line-height: 4rem;
  text-transform: uppercase;
  margin-bottom: 0.5rem;

  @media only screen and (min-width: 936px) {
    font-size: 7rem;
    line-height: 7rem;
    margin-bottom: 0;
    margin-right: 3.5rem;
    padding-bottom: 0.5rem;
    text-align: right;
  }
`;

export const WelcomeText = styled.h1`
  font-family: var(--content-font);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.25rem;
  text-align: center;

  & span:first-child {
    display: block;
  }

  @media only screen and (min-width: 936px) {
    font-size: 1.75rem;
    line-height: 2.25rem;
    margin-bottom: 0;
    margin-left: 3.5rem;
    text-align: left;

    & span {
      display: block;
    }
  }
`;

export const IllustrationWrapper = styled.div`
  margin: 0.75rem 0.75rem 1.5rem;

  @media only screen and (min-width: 700px) {
    margin: 0.75rem 1.75rem 1.5rem;
  }

  @media only screen and (min-width: 936px) {
    display: flex;
    justify-content: center;
    width: 84%;

    margin: 1.5rem auto;
    max-width: 90.5rem;
  }
`;
