import { ProjectList, Tabs, Title } from 'components';

import { Container } from './styled';
import { projectPreviews } from 'data';
import { useState } from 'react';

export const Projects = (): JSX.Element => {
  const [activeTab, setActiveTab] = useState(0);

  const handleActiveTab = (index: number): void => {
    setActiveTab(index);
  };

  const projectTypes = Object.keys(projectPreviews);

  const professionalPreviews = projectPreviews.professional.filter(
    project => project.homepagePreview === true
  );

  const personalPreviews = projectPreviews.personal.filter(
    project => project.homepagePreview === true
  );

  return (
    <Container id="portfolio">
      <Title>Portfolio</Title>

      <Tabs
        activeTab={activeTab}
        handleActiveTab={handleActiveTab}
        tabs={projectTypes}
      />

      {activeTab === 0 && (
        <ProjectList
          id="professional-preview"
          link="/professional"
          projects={professionalPreviews}
          seeMoreButton
        />
      )}

      {activeTab === 1 && (
        <ProjectList
          id="personal-preview"
          link="/personal"
          projects={personalPreviews}
          seeMoreButton
        />
      )}
    </Container>
  );
};
