import styled from 'styled-components';

export const StyledFooter = styled.footer`
  color: var(--grey);
  padding: 0 1.75rem;

  display: flex;
  align-items: center;
  justify-content: space-between;

  height: 4.6rem;
  z-index: 3;

  @media only screen and (min-width: 936px) {
    height: 3rem;
    padding: 0 8.25rem;
  }
`;

export const CopyrightLinks = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5rem;

  @media only screen and (min-width: 700px) {
    flex-direction: row;
    align-items: center;
    gap: 1rem;
  }
`;

export const Copyright = styled.div`
  font-size: 0.75rem;
`;

export const BadgeWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const ScrollToTopButton = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;

  color: var(--primary);

  min-width: 8.75rem;

  svg {
    color: var(--primary);
    margin-right: 0.5rem;
  }

  &:hover {
    color: var(--primary);
  }
`;
