import styled from 'styled-components';
import { Button } from 'components';

export const StyledAbout = styled.section``;

export const Background = styled.div`
  background-color: var(--light_03);
`;

export const Content = styled.div`
  padding: 1.75rem 1.75rem 2.75rem;

  display: grid;
  grid-template-columns: 1;
  grid-template-rows: 2;

  @media only screen and (min-width: 700px) {
    grid-template-columns: 2;
    grid-template-rows: 1;

    padding: 1.75rem;
  }

  @media only screen and (min-width: 936px) {
    padding: 2rem 9.25rem;

    margin-left: auto;
    margin-right: auto;
    max-width: 90.5rem;
  }
`;

export const CVButton = styled(Button)`
  border-radius: 9px;
  font-size: 1.25rem;
  min-width: 6rem;
  padding: 0.5rem 1rem;

  @media only screen and (min-width: 700px) {
    font-size: 1.5rem;
    min-width: 12rem;
  }
`;

export const ProfilePicWrapper = styled.div`
  grid-column: 1;
  grid-row: 1;
  justify-self: center;

  border: 2px solid var(--primary) !important;
  border-radius: 50%;
  box-shadow: 0 1px 8px 0 var(--shadow_05);
  margin-bottom: 1.75rem;
  overflow: hidden;

  height: 10rem;
  width: 10rem;

  @media only screen and (min-width: 700px) {
    align-self: center;
    margin-bottom: 0;

    height: 18rem;
    width: 18rem;
  }
`;

export const Heading = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  @media only screen and (min-width: 1060px) {
    align-items: center;

    h2 {
      font-size: 2.75rem;
      line-height: 1;
      margin-bottom: 0.2rem;
    }
  }
`;

export const Details = styled.div`
  @media only screen and (min-width: 700px) {
    grid-column: 2;
    grid-row: 1;

    padding: 0 2.5rem;
  }
`;

export const Skills = styled.div`
  padding: 1.75rem;

  @media only screen and (min-width: 700px) {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
  }

  @media only screen and (min-width: 936px) {
    padding: 2rem 9.25rem;

    margin-left: auto;
    margin-right: auto;
    max-width: 90.5rem;
  }
`;
