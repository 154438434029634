import styled from 'styled-components';

export const Container = styled.div`
  background-color: var(--light_02);
  box-shadow: 0 2px 2px 0 var(--shadow);
  padding: 1rem;
  text-align: center;

  position: absolute;
  top: 3.8rem;
  left: 0;
  right: 0;

  width: 100%;
`;

export const List = styled.ul`
  display: flex;
  flex-direction: column;

  list-style: none;
  padding-left: 0;
`;

export const Item = styled.li`
  color: var(--grey);
  cursor: pointer;
  padding: 0.5rem 0;
  text-transform: uppercase;

  &:hover {
    color: var(--primary);
  }
`;
