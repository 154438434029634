import {
  ContactButton,
  Content,
  Header,
  Illustration,
  StyledContact,
  Text,
} from './styled';

import NextImage from 'next/image';
import contactBanner from 'assets/backgrounds/contact_banner.png';
import contactBannerMobile from 'assets/backgrounds/contact_banner_mobile.png';

export const Contact = (): JSX.Element => {
  const onContactClick = () => {
    
  };

  return (
    <StyledContact id="contact">
      <Illustration>
        <NextImage
          alt="Contact Banner"
          placeholder="blur"
          src={contactBannerMobile}
          style={{ height: '100%', objectFit: 'cover', width: '100%' }}
        />
      </Illustration>
      <Illustration $isDesktop>
        <NextImage
          alt="Contact Banner"
          placeholder="blur"
          src={contactBanner}
          style={{ height: '100%', objectFit: 'cover', width: '100%' }}
        />
      </Illustration>
      <Content>
        <Header>Contact</Header>
        <Text>Interested in working together?</Text>
        <ContactButton ariaLabel="Get in touch via email" label="Get in touch" href="mailto:zivpesso@gmail.com" />
      </Content>
    </StyledContact>
  );
};
