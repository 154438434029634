import { Carousel, Title } from 'components';
import { Container } from './styled';

export const Testimonials = (): JSX.Element => {
  return (
    <Container>
      <Title>Testimonials</Title>
      <Carousel />
    </Container>
  );
};
