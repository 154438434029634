import styled from 'styled-components';

export const Nav = styled.nav`
  background-color: var(--light_02);
  box-shadow: 0 2px 2px 0 var(--shadow);
  padding: 0.5rem 1rem;

  position: fixed;
  top: 0;
  right: 0;
  left: 0;

  height: 4rem;
  z-index: 1030;

  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
`;

export const NavLinks = styled.div`
  display: none;

  align-items: flex-end;
  gap: 2rem;

  line-height: 3rem;
  text-transform: uppercase;

  margin-left: auto;
  margin-right: 2rem;

  a:hover {
    color: var(--primary);
  }

  @media only screen and (min-width: 1024px) {
    display: flex;
  }
`;

export const Socials = styled.div`
  display: flex;
  column-gap: 0.9rem;

  @media only screen and (min-width: 936px) {
    column-gap: 1.75rem;
  }

  @media only screen and (min-width: 1024px) {
    margin-left: 2rem;
  }
`;

export const Menu = styled.button`
  height: 2.8rem;
  width: 2.8rem;

  background: transparent;
  border: none;

  display: flex;
  align-items: center;
  justify-content: center;

  @media only screen and (min-width: 1024px) {
    display: none;
  }
`;
