import {
  Divider,
  IllustrationWrapper,
  StyledHeader,
  Welcome,
  WelcomeText,
} from './styled';

import NextImage from 'next/image';
import landingPageArt from 'assets/backgrounds/landing_page_art.png';

export const Header = (): JSX.Element => (
  <>
    <StyledHeader>
      <Welcome>Welcome</Welcome>
      <Divider />
      <WelcomeText>
        <span className="insideText">I&apos;m Ziv Pesso,</span>
        <span className="insideText"> a UI Artist with</span>
        <span className="insideText"> Concept Art experience</span>
      </WelcomeText>
    </StyledHeader>
    <IllustrationWrapper>
      <NextImage
        alt="Landing Page Art"
        placeholder="blur"
        src={landingPageArt}
        style={{ height: '100%', objectFit: 'cover', width: '100%' }}
      />
    </IllustrationWrapper>
  </>
);
