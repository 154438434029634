import styled from 'styled-components';

export const Content = styled.div`
  padding: 1.75rem;

  @media only screen and (min-width: 936px) {
    padding: 0.5rem 8.25rem 2rem;
    margin-left: auto;
    margin-right: auto;
    max-width: 90.5rem;
  }
`;

export const ProjectGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  grid-gap: 1rem;
  align-items: center;
  justify-items: center;

  text-align: center;

  a {
    width: 100%;
  }

  @media only screen and (min-width: 700px) {
    div {
      height: 31.25rem;
    }
  }

  @media only screen and (min-width: 936px) {
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));

    div {
      height: 15.6rem;
    }
  }
`;

export const Wrapper = styled.div`
  border-radius: 9px;
  box-shadow: 0px 3px 4px 0px var(--shadow_03);
  cursor: pointer;
  overflow: hidden;
  position: relative;

  height: 15.6rem;
  width: 100%;

  img {
    transition: 1s ease;
  }

  &:hover img {
    transform: scale(1.25);
  }
`;
