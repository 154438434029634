import { Button } from 'components/Button';
import styled from 'styled-components';

export const StyledContact = styled.section`
  position: relative;

  margin-bottom: -6px;
`;

export const Content = styled.div`
  position: absolute;
  top: 0;
  left: 0;

  display: flex;
  flex-direction: column;

  width: 100%;
  z-index: 1;

  padding: 1.75rem;

  @media only screen and (min-width: 700px) {
    top: 8rem;
  }

  @media only screen and (min-width: 936px) {
    top: 0;

    height: 100%;
    align-items: center;
    justify-content: center;
  }
`;

export const Header = styled.h2`
  line-height: 1.2;

  @media only screen and (min-width: 700px) {
    font-size: 2.5rem;
  }

  @media only screen and (min-width: 936px) {
    font-size: 3.5rem;
    line-height: 3.5rem;
  }
`;

export const Illustration = styled.div<{ $isDesktop?: boolean }>`
  grid-column: 1;
  grid-row: 1;

  width: 100%;

  display: ${({ $isDesktop }) => ($isDesktop ? 'none' : 'block')};

  @media only screen and (min-width: 936px) {
    display: ${({ $isDesktop }) => ($isDesktop ? 'block' : 'none')};
  }
`;

export const Text = styled.p`
  width: 9rem;
  margin-bottom: 1rem;

  @media only screen and (min-width: 700px) {
    font-size: 1.5rem;
    width: 25rem;
  }

  @media only screen and (min-width: 936px) {
    font-size: 1.7rem;
    margin: 1rem auto;
  }
`;

export const ContactButton = styled(Button)`
  border-radius: 9px;
  font-size: 1.25rem;

  align-self: flex-start;

  height: 3.5rem;
  width: 10rem;

  @media only screen and (min-width: 700px) {
    font-size: 2rem;
    width: 22rem;
  }

  @media only screen and (min-width: 700px) {
    font-size: 3rem;
    height: auto;
    margin: 0 auto;
    min-width: 21.5rem;
    padding: 1rem;
    width: 28rem;
  }
`;
