import { Container, Item, List } from './styled';

import { DropdownMenuProps } from './types';

export const DropdownMenu = ({
  toggleMenu,
}: DropdownMenuProps): JSX.Element => {
  const navigateToSection = (section: string): void => {
    toggleMenu();
    window.location.replace(section);
  };

  return (
    <Container id='navbarSupportedContent'>
      <List id='main-nav'>
        <Item onClick={() => navigateToSection('/#portfolio')}>Portfolio</Item>
        <Item onClick={() => navigateToSection('/#about')}>About</Item>
        <Item onClick={() => navigateToSection('/#contact')}>Contact</Item>
      </List>
    </Container>
  );
};
