import styled from 'styled-components';

export const Container = styled.section`
  height: calc(100vh - 104px);
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  background-image: var(--gradient_primary);
  color: var(--light_01);
`;
